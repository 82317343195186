@import "~bootstrap/dist/css/bootstrap.min.css";

html {
  width: 100%;
  height: 100%;
  position: absolute;
  scroll-behavior: smooth !important;
  scroll-padding-top: 60px;
}

::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0px;
  padding: 0px;
  color: #212a40;
  position: relative;
  width: 100%;
  background: #fff;
  font-size: 14px;
  font-family: "DM Sans", sans-serif;
}

.authpage {
  background-color: #eff4f5;
}

.headbg {
  padding: 9px 16px !important;
  background: none;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 999;
  background-color: #fff;
  border-bottom: 5px solid transparent;
  border-image: linear-gradient(to right, #E8A7E6, #B3B4F6);
  border-image-slice: 1;
}

.headbg .navbar-brand {
  font-size: 24px;
  font-weight: 800;
  color: #0E100F;
  display: block;
  transition: 0.3s;
}

.headbg .navbar-nav a {
  padding: 18px 16px;
  margin: 0px 12px;
  color: #212a40;
  font-size: 16px;
  position: relative;
  font-weight: 700;
}

section {
  padding: 45px 0px;
}

.homebannerbg {
  position: relative;
  z-index: 9;
  padding-top: 12vh;
}

.homebannerbg .sitebannercontent {
  padding-top: 0vh;
}

.sitebannercontent {
  position: relative;
}

.sitebannercontent::before {
  content: "";
  background-image: url('../public/images/smoke1.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.proimgbg {
  background: #F5F5F5;
  border: 1px solid #F5F5F5;
  width: 100%;
  max-width: 114px;
  height: 114px;
  margin: 0 auto;
  display: flex;
  border-radius: 6px;
  align-items: center;
  padding: 5px;
  margin-bottom: 14px;
  /* margin-top: -117px; */
  border-radius: 150px;
  justify-content: center;
}

.proimg {
  width: 100%;
  max-width: 114px;
  border-radius: 50px;
  height: 100%;
  object-fit: cover;
}

.custombadge {
  color: #40475a !important;
  padding: 8px 24px !important;
  border: 1px solid #0a0a0c !important;
  border-radius: 50px !important;
  font-size: 16px;
  background-color: transparent !important;
}

.badgeimg {
  width: 100%;
  max-width: 16px;
}

.maintitl {
  font-size: 84px;
  font-weight: 700;
  color: #212a40;
}

.content {
  font-size: 22px;
}

.sitebtn, .foliobtn {
  background: #16a34a;
  /* background: linear-gradient(256deg, var(--token-4734062f-3131-4e08-8242-6c5c95db38c8, rgb(6, 87, 208)) 0%, var(--token-28d52507-1d1c-483a-b3fb-d6ed8d201eff, rgb(31, 120, 255)) 148%) !important; */
  padding: 12px 18px;
  border-radius: 22px;
  color: #ffffff;
  font-weight: 700;
  border-color: #16a34a;
}

.sitebtn:hover,
.sitebtn:active,
.sitebtn:focus {
  color: #ffffff !important;
  background: #16a34a !important;
  border-color: #16a34a !important;
  
}

.foliobtn {
  border-color: rgb(6, 87, 208) !important;
  background: linear-gradient(256deg, var(--token-4734062f-3131-4e08-8242-6c5c95db38c8, rgb(6, 87, 208)) 0%, var(--token-28d52507-1d1c-483a-b3fb-d6ed8d201eff, rgb(31, 120, 255)) 148%) !important;
}

.btnflex {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.stackicons {
  width: 100%;
  max-width: 48px;
}

.h3 {
  font-size: 58px;
  font-weight: 700;
}

.techstacks {
  background: linear-gradient(180deg, #f0f4fa 0%, rgb(255, 255, 255) 100%);
}

.stackflexbg {
  background-color: rgb(255, 255, 255);
  border-radius: 16px;
  opacity: 1;
  text-align: center;
  padding: 20px;
  border-radius: 22px;
  border: 1px solid #F2F4F7;
  flex: 0 1 calc(25% - 14px);
  /* Adjust width for 4 items per row with some margin */
  margin-bottom: 20px;
  /* Space between rows */
  box-shadow: rgba(0, 0, 0, 0.03) 0px 16px 40px 0px;
}

.h6 {
  font-size: 16px;
  font-weight: 700;
}

.stackflex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 14px;
}

.projects {
  background: linear-gradient(180deg, #f0f4fa 0%, rgb(255, 255, 255) 100%);
}

.projimgbg {
  background: #F5F5F5;
  border: 1px solid #F5F5F5;
  width: 100%;
  max-width: 260px;
  margin: 0 auto;
  display: flex;
  border-radius: 6px;
  align-items: center;
  margin-bottom: 14px;
  border-radius: 150px;
  justify-content: center;
}

.projectimg {
  width: 100%;
  max-width: 260px;
  border-radius: 20px;
}

.projectflex {
  display: flex;
  gap: 16px;
  justify-content: center;
  flex-wrap: wrap;
}

.projectflexcont {
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 16px 40px 0px;
  border-radius: 32px;
  padding: 32px;
  border: 1px solid #F2F4F7;
}

.contact {
  background: #0D6EFD;
}

.contact h3,
.contact p {
  color: #ffffff;
}

.contact .sitebtn {
  background-color: #ffffff !important;
  color: #212a40 !important;
}

.toolicon {
  width: 100%;
  max-width: 18px;
}

.contact .toolicon {
  filter: invert(1);
}

.formcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  gap: 12px;
}

.formboxbg {
  padding: 48px;
  border: 1px solid #057bdc1d;
  width: 448px;
  border-radius: 10px;
  background: #fff;
}

.formboxbg .form-control {
  height: 50px;
  border-radius: 6px;
  background: #fbfbfb;
}

.formboxbg .form-control {
  border-color: #70707038 !important;
  box-shadow: none !important;
}

.formboxbg .form-label {
  color: #212a40;
  font-size: 16px;
  font-weight: 600;
}

.authbtn {
  padding: 8px 12px;
  border-radius: 6px;
}

.authtitlebg h2 {
  color: #212a40;
  font-size: 32px;
  font-weight: 700;
}

.authtitlebg h6 {
  color: #212a40;
  font-size: 18px;
  font-weight: 600;
}

.authbgimg {
  position: absolute;
  left: 0;
  bottom: 0;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media (max-width: 1200px) {
  .maintitl {
    font-size: 64px;
    line-height: normal;
  }

  .h3 {
    font-size: 48px;
    line-height: normal;
  }

}

@media (max-width: 1080px) {
  .stackflexbg {
    flex: 0 1 calc(25% - 10px);
    /* Still 4 items per row */
  }

  .h3 {
    font-size: 38px;
  }

  .maintitl {
    font-size: 52px;
  }
}

@media (max-width:991px) {
  .projectflex {
    flex-wrap: wrap;
  }

  .projectflex>div {
    width: 48%;
  }

  .content {
    font-size: 20px;
  }

  .btnflex {
    flex-wrap: wrap;
  }
}

@media (max-width:767px) {
  .projectflex>div {
    width: auto;
  }

  .projectflexcont {
    padding: 20px;
  }

  .maintitl {
    font-size: 38px;
  }

  .h3 {
    font-size: 30px;
  }

  .stackflexbg {
    flex: 0 1 calc(50% - 10px);
    /* 2 items per row on even smaller screens */
  }
}

@media (max-width: 576px) {
  .stackflexbg {
    flex: 0 1 100%;
    /* 1 item per row on mobile screens */
  }

}

@media (max-width:500px) {
  .timeline ul li {
    max-width: 350px;
  }
}

@media (max-width:400px) {
  .timeline ul li {
    max-width: max-content;
  }
}