.timeline{
    position:relative;
    margin:25px auto;
    width:1000px;
    box-sizing:border-box;
  }
  .timeline:before{
    content: '';
    position: absolute;
    left: 0%;
    width: 2px;
    height: 100%;
    background: #dbdbdb;
    /* top: 16%; */
  }
  .timeline ul{
    padding:0;
    margin:0;
  }
  .timeline ul li{
    list-style:none;
    position:relative;
    width:100%;
    padding:20px 40px;
    box-sizing:border-box;
    margin: 16px 0px 16px 16px;
    background: linear-gradient(270deg,#f5f7fa 0%,rgb(255,255,255) 100%);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .timeline ul li:nth-child(even){
    /* float:right; */
    text-align:left;
    clear:both;
  }
  .timelinecontent{
    padding-bottom:20px;
  }
  .timeline ul li:before
  {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    top: 24px;
    left: -26px;
    background: #f2f2f2;
    border-radius: 50%;
    box-shadow: 0 0 0 3px #f2f2f2;
  }
  .timeline ul li h3{
    padding:0;
    margin:0;
    color:#212a40;
    font-weight:700;
    font-size: 20px;
  }
  .timeline ul li p{
    margin:10px 0 0;
    padding:0;

  }
  .timeline ul li .time h4{
    margin:0;
    padding:0;
    font-size:14px;
  }
  .timeline ul li .time
  {
    position:absolute;
    top:22px;
    left:-165px;
    margin:0;
    padding:8px 16px;
    font-size: 16px;
    background: #0D6EFD;
    color:#ffffff;
    font-weight: 700;
    border-radius:18px;
    box-shadow:0 0 0 3px #0D6EFD;
  }
  @media (max-width: 1400px) {
    .timeline {
      margin: 0 auto;
      width: 600px;
    }
  }
  @media(max-width:1000px)
  {
    .timeline{
      width:100%;
      max-width: 400px;
    }
    .timeline ul li {
      padding: 12px 28px;
      margin: 50px 0px 50px 12px;
    }
  }
  @media(max-width:767px){
    .timeline{
      width:100%;
      padding: 40px 0px 0px 0px;
    }
    .timeline ul li h3 {
      font-size: 18px;
    }
    .timeline ul li p {
      font-size: 20px;
    }
    h1{
      font-size:40px;
      text-align:center;
    }
    .timeline:before{
      left:14px;
    }
    .timeline ul li
    {
      width:100%;
      text-align:left;
      padding: 12px 28px;
    }
    .timeline ul li
    {
      top:-18px;
      left:16px;
    }
    .timeline ul li .time {
      top: -40px;
      left: 50px;
      right: inherit;
  }
  }
  .clearclass {
    clear: both;
  }