html {
    width: 100%;
    height: 100%;
    position: absolute;
}

.usernav {
    margin: 0px;
    padding: 0px;
    background: #ffffff;
    /* background: linear-gradient(225deg, var(--token-47ef7e86-9ea6-47bf-9a30-889f143a325f, #ffffff) 10%, var(--token-08abdfee-17c5-4399-9a6f-7f5d70d35402, rgb(200, 211, 227)) 100%); */
    color: #000000;
    font-size: 14px;
    position: relative;
    min-height: 100%;
    width: 100%;
    /* padding-bottom: 60px; */
}

.mobhide {
    display: none;
}

.brand {
    margin-bottom: 24px;
}

.leftsidemenu {
    position: fixed;
    left: 0;
    top: 0;
    background: #ffffff;
    width: 250px;
    height: 100%;
    z-index: 99;
    overflow-x: hidden;
    transition: 0.5s;
    border-right: 1px solid #E5E5E5;
}
a {
    color: #16a34a;
}
.leftsidemenu::before {
    content: "";
    position: absolute;
    background-image: url('../public/images/fog.svg');
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
    width: 50%;
    height: 100%;
    aspect-ratio: .986013986013986 / 1;
    filter: blur(120px);
    z-index: -1;
    top: 0;
    bottom: 0;
}

.leftsidemenu a {
    padding: 12px 8px 12px 24px;
    margin: 6px;
    text-decoration: none;
    font-size: 16px;
    color: #212a40;
    font-weight: 600;
    display: block;
    transition: 0.3s;
    border-radius: 10px;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
}

.leftsidemenu a.active {
    background: #16a34a;
    /* background: linear-gradient(256deg, var(--token-4734062f-3131-4e08-8242-6c5c95db38c8, rgb(6, 87, 208)) 0%, var(--token-28d52507-1d1c-483a-b3fb-d6ed8d201eff, rgb(31, 120, 255)) 148%); */
    color: #f2f9fe;
    opacity: 1;
    border-radius: 8px;
}
.leftsidemenu a.active img {
    filter: invert(1);
}
.leftsidemenu a:hover {
    color: #212a40;
    background: #f6f6f6;
}

.leftsidemenu a:hover h6 {
    color: #fff;
}

.leftsidemenu .active:hover {
    color: #f2f9fe;
    opacity: 1;
    background: #16a34a;
}

.closesidenav .leftsidemenu {
    width: 0px;
}

.closesidenav .leftsidemenu {
    width: 0px;
}

.usernavbar {
    padding-left: 272px;
    padding-right: 16px;
    background: #ffffff;
    /* background: linear-gradient(225deg,var(--token-47ef7e86-9ea6-47bf-9a30-889f143a325f, #ffffff) 10%,var(--token-08abdfee-17c5-4399-9a6f-7f5d70d35402, rgb(200, 211, 227)) 100%); */
    color: #1a181e;
    box-shadow: none;
    border-bottom: 1px solid #d9d9d9;
}

.brand a, .mobnav a{
    padding: 8px 8px 8px 24px;
    margin: 6px;
    text-decoration: none;
    font-size: 24px;
    font-weight: 900;
    color: #212a40;
    display: block;
    transition: 0.3s;
}

.brand a:hover {
    background-color: unset;
    /* color: #f2f9fe; */
}

.sidenavflex {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 0px 10px;
}

.pagecontentbox {
    padding: 16px 16px 16px 272px;
    padding-left: 272px;
}
.pagecontentbox h6 {
    color: #212a40;
}
.opendash {
    transition: all 0.7s ease;
}

.opendash.active {
    left: 248px;
    transition: all 0.7s ease;
}

/* title */

.pagetitlediv h2 {
    color: #212a40;
    font-size: 18px;
    font-weight: 600;
}

.menuimg {
    width: 100%;
    max-width: 38px;
}

.table-content {
    display: table;
    margin: 0px;
    width: 100%;
}

.table-content>div:first-child {
    width: 52px;
}

.table-content>div {
    display: table-cell;
    vertical-align: middle;
    float: none;
}

.menuprofilebg {
    background-color: #fff;
    border-radius: 12px;
}

.menuprofile h6 {
    color: #40475a;
}

.menuprofile p {
    font-size: 14px;
    margin-bottom: 0px;
}

.menuprofile span img {
    width: 36px;
    height: 36px;
    border-radius: 50px;
    overflow: hidden;
    object-fit: fill;
}

.pagetitle .pagetitleh2 {
    font-size: 30px;
    color: #212a40;
    font-weight: 700;
}

.titlespan a {
    text-decoration: none;
}

.pagetitleh2 {
    display: flex;
    align-items: center;
    gap: 24px;
}

.userpagecontent {
    font-size: 16px;
    color: #737373;
}

.profileimgbox .proimgbg {
    margin: 0px;
}

.profileimgbox .form-control {
    max-width: 300px;
}

.profileimgbox {
    position: relative;
    overflow: hidden;
}

.userbtn {
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 6px;
}

.borderbtn {
    background-color: transparent !important;
    color: #212a40;
    border-color: #212a40;
}

.borderbtn:hover {
    color: #212a40 !important;
    border-color: #212a40 !important;
}

.biobg .form-control {
    height: 84px !important;
    border-radius: 10px;
}

.biobg .form-control.headingform {
    height: 64px !important;
    border-radius: 10px;
}

.form-control:active {
    box-shadow: none;
}

.form-control:focus {
    box-shadow: none;
}

.formflex {
    display: flex;
    gap: 12px;
    align-items: center;
    width: 100%;
}

.pagebannerflex {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    background: #f5f5f5;
    padding: 20px;
    border-radius: 6px;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #00000000), var(--tw-shadow);
    border: 1px solid #E5E5E5;
    overflow: hidden;
}

.themeflex {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.themes {
    position: relative;
}

.bannerimg {
    width: 100%;
    max-width: 300px;
}

.themeimgbg {
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #00000000), var(--tw-shadow);
    border: 1px solid #E5E5E5;
    display: flex;
    width: 300px;
    height: 300px;
    background-color: #f5f5f5;
    overflow: hidden;
    border-radius: 8px;
    padding: 10px 32px;
}

.themeimg {
    width: 100%;
    max-width: 300px;
    object-fit: cover !important;
}

.applybtn {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    background: linear-gradient(180deg, var(--token-c7fa0745-7201-4aeb-a84c-fafd6174380d, #14161e) 0%, var(--token-3854456b-5064-4658-bdfd-0b29f6aa355d, rgb(7, 12, 20)) 100%);
    padding: 16px 0px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3),
        0px 8px 12px rgba(0, 0, 0, 0.2),
        0px 12px 16px rgba(0, 0, 0, 0.1);
}

.navmenudrop .dropdown-menu {
    right: 0px;
    left: inherit;
}

.menuimg {
    width: 100%;
    max-width: 32px;
}

.navmenudrop button {
    background: none !important;
    border: none !important;
}

.navmenudrop .dropdown-toggle::after {
    display: none;
}

.customizebtn {
    background: #16a34a !important;
    border-color: #16a34a !important;
}

.applybtn {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.prevlink {
    font-size: 18px;
    color: #ffffff;
    text-decoration: none;
}

.previmg {
    width: 100%;
    max-width: 14px;
}

.menuicons {
    width: 100%;
    max-width: 16px;
    /* filter: invert(1); */
}

.editflexbox {
    display: flex;
    gap: 20px;
}

.editflexbox>div:first-child {
    width: 30%;
    height: fit-content;
}

.editflexbox>div:last-child {
    width: 70%;
}

.landingmenusection,
.landingsections {
    background: #f5f5f5;
    padding: 20px;
    border-radius: 6px;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #00000000), var(--tw-shadow);
    border: 1px solid #E5E5E5;
}

.landingsectionflex {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.landingmenusection .nav-item a {
    font-size: 16px;
    font-weight: 700;
    color: #212a40;
    margin: 3px;
}

.landingmenusection .nav-item a:hover {
    background: linear-gradient(90deg, rgb(0 0 0 / 14%) 0%, rgb(246 246 246 / 10%) 100%);
    color: #212a40;
    border-radius: 8px;
}

.landingmenusection .nav-item .nav-link.active {
    background: #16a34a;
    color: #f2f9fe;
}

.landingsections h6 {
    color: #212a40;
}

.sectionsubtitle {
    font-size: 16px;
    color: #6B7A85;
    font-weight: 600;
}

.aibtn {
    animation: pulse 1.5s infinite;
    background-image: linear-gradient(to bottom right, #23ffae, #16a34a);
    border: none;
}

.getdetailsbox {
    position: relative;
    display: flex;
    justify-content: center;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 6px #0000001a;
}

.getdetailsbox .form-control {
    max-width: 400px;
}

.fomradioflex {
    display: flex;
    gap: 16px;
}

.getdetailsbox::before {
    content: "";
    position: absolute;
    background-image: url('../public/images/probg.png');
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
    width: 30%;
    right: 0;
    bottom: 0;
    height: 100%;
}

.welcomdashimg {
    width: 100%;
    max-width:  360px;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 #1c459582;
    }

    70% {
        box-shadow: 0 0 0 10px #fff0;
    }
}

.createbanner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #00000000), var(--tw-shadow);
    border: 1px solid #E5E5E5;
}

.createbanner .pagetitleh2 {
    color: #212a40;
}

.createbanner p {
    color: #485e79;
}

.modal-content {
    background-color: #f5f5f5;
    border: 1px solid #E5E5E5;
    position: relative;
    overflow: hidden;
    z-index: 9;
}

.modal-header {
    border-bottom: none !important;
}

.modal-footer {
    border-top: none !important;
}

.modal-content .form-control {
    height: 48px;
    border-radius: 10px;
    border: none;
}

.modal-title {
    font-size: 20px;
    font-weight: 600;
    color: #212a40;
}

.modal-content label {
    font-size: 16px;
    color: #828e9d;
    font-weight: 600;
}

.modal-content small {
    color: #828e9d;
}

.modal-content .form-control {
    background: #e2e2e2;
    color: #212a40;
}

.modal-content .form-control:active,
.modal-content .form-control:focus {
    border: none !important;
}
.react-datepicker__input-container input {
    height: 36px;
    border: 1px solid #dee2e6 !important;
    border-radius: 0.375rem;
}
.opendash {
    display: none;
}

.hamicon {
    width: 100%;
    max-width: 38px;
}
.hamiconbtn {
    background-color: transparent !important;
    border-color: unset !important;
    padding: 0;
}

.bg-body-tertiary {
    border-bottom: 1px solid #E5E5E5;
}

.hamiconnav .mobnav{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0px 20px;
}

.mobnav a {
    padding: 0px;
}

@media (max-width: 1300px) {
    .bannerimg {
        max-width: 180px;
    }
}


@media (max-width : 1080px) {
    .formflex {
        flex-wrap: wrap;
    }
}

@media (max-width: 991px) {

    .opendash {
        display: block;
        cursor: pointer;
        background-color: #f5f5f5;
        border-bottom-right-radius: 15px;
        border-top-right-radius: 15px;
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #00000000), var(--tw-shadow);
        border: 1px solid #E5E5E5;
        left: 0;
        padding: 8px;
        position: fixed;
        top: 50%;
        z-index: 9999;
    }
    .opendash .opendashicon{
        width: 100%;
        max-width: 48px;
    }
    .pagecontentbox {
        padding: 16px !important;
    }
    .leftsidemenu {
        visibility: hidden;
        /* opacity: 0; */
        translate: -32vh 0;
        transition-property: display opacity;
        transition-duration: 1s;
        transition-behavior: allow-discrete;
        animation: slideOut 1s backwards;
    }
    .leftsidemenu.active {
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2), 
                -5px -5px 15px rgba(255, 255, 255, 0.9);
        visibility: visible;
        animation: slideIn 0.7s forwards;
        border-bottom-right-radius: 1rem;
        border-top-right-radius: 1rem;
        /* opacity: 1; */
    }
    @keyframes slideIn {
        0% {
            transform: translate(0, 0);
        }
        100% {
            
            transform: translate(32vh, 0);
        }
      }
      @keyframes slideOut {
        0% {
            transform: translate(32vh, 0);
        }
        100% {
            
            transform: translate(-32vh, 0);
        }
      }
      .createbanner {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }
      .editflexbox {
        flex-wrap: wrap;
        flex-direction: column;
      }
      .editflexbox > div {
        width: 100% !important;
      }
      .landingmenusection .flex-column.nav {
        flex-wrap: nowrap !important;
        flex-direction: row !important;
        overflow-x: scroll;
      }
      .formflex {
        flex-wrap: wrap;
      }
      .landingmenusection .nav-item a {
        white-space: nowrap;
      }
      .pagebannerflex {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .bannerimg {
        width: max-content;
        height: 100%;
      }
      .pagebannerimgbg {
        margin-left: auto;
      }
      .themeflex {
        align-items: center;
        justify-content: space-between;
      }
      .landingsections .formflex {
        margin-top: 20px;
      }
}

@media (max-width:767px) {
    .opendash .opendashicon {
        max-width: 34px;
    }
    .pagetitle .pagetitleh2 {
        font-size: 22px;
    }
}